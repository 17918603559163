import { register, init } from "marko/dist/runtime/components/index.js";
import "../../components/app-layout/style.css";
import "../../components/app-layout/favicon.png";
import "../../components/app-layout/components/skip-link/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/components/app-layout/components/skip-link/index.marko?virtual=./index.marko.css";
import "../../components/app-layout/components/layout-header/style.css";
import "../../logos/marko.svg";
import "../../logos/marko-uwu.png";
import "../../components/app-layout/components/layout-search/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/components/app-layout/components/layout-search/index.marko?virtual=./index.marko.css";
import component_0 from "../../components/app-layout/components/layout-search/component-browser.js";
register("uja7Vjk", component_0);
import component_1 from "../../components/app-layout/components/layout-header/component-browser.js";
register("IZr$9Bl", component_1);
import "../../components/app-layout/components/layout-sidebar/style.css";
import component_2 from "../../components/app-layout/components/layout-sidebar/components/version-switcher/component-browser.js";
register("wIkyy5p", component_2);
import component_3 from "../../components/app-layout/components/layout-sidebar/component-browser.js";
register("G0dDyNr", component_3);
import "../../components/app-footer/style.css";
import "../../components/app-footer/openjsf.svg";
import "../../components/app-footer/osi.svg";
import "../../components/app-footer/ebay.svg";
import "../../components/discord-link/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/components/discord-link/index.marko?virtual=./index.marko.css";
import "../../logos/discord.svg";
import "./components/home-hero/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-hero/index.marko?virtual=./index.marko.css";
import "./components/home-hero/marko-text.svg";
import "./components/home-hero/components/github-link/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-hero/components/github-link/index.marko?virtual=./index.marko.css";
import "../../logos/github.svg";
import component_4 from "./components/home-hero/components/github-link/component-browser.js";
register("FAFc9gd", component_4);
import component_5 from "./components/home-hero/component-browser.js";
register("FLbBM5m", component_5);
import "./components/home-features/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-features/index.marko?virtual=./index.marko.css";
import "./components/home-language/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-language/index.marko?virtual=./index.marko.css";
import "./components/home-feature-block/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-feature-block/index.marko?virtual=./index.marko.css";
import "../../components/heading/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/components/heading/index.marko?virtual=./index.marko.css";
import "./components/home-language/components/counter-tags.marko";
import "./components/home-language/components/counter-example/index.marko";
import "./components/home-streaming/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-streaming/index.marko?virtual=./index.marko.css";
import "./components/home-streaming/components/scroll-locked-stream-example/index.marko";
import "./components/home-hydration/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-hydration/index.marko?virtual=./index.marko.css";
import "./components/home-demo-page/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-demo-page/index.marko?virtual=./index.marko.css";
import "./components/home-demo-page/product.png";
import "./components/home-demo-page/x.svg";
import "./components/home-performance/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-performance/index.marko?virtual=./index.marko.css";
import "./components/home-performance/arrow.svg";
import "./components/home-tooling/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-tooling/index.marko?virtual=./index.marko.css";
import "./components/home-tooling/screen.png";
import "./components/home-community/index.marko.css!=!/Users/dpiercey/dev/marko-js/website/node_modules/@marko/webpack/dist/loader/index.js!/Users/dpiercey/dev/marko-js/website/src/pages/index/components/home-community/index.marko?virtual=./index.marko.css";
import "../../logos/stackoverflow.svg";
import "../../logos/twitter.svg";
init();